import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import WhatDoYouGain from '@/components/SolutionPages/WhatDoYouGain'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import richTextOptions from '@/utils/richTextOptions'

const WhatDoYouGainOwner: React.FC = () => {
  const {
    allContentfulWhatDoYouGain: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulWhatDoYouGain(
        filter: { solutionName: { eq: "owner" } }
      ) {
        nodes {
          richText {
            raw
          }
        }
      }
    }
  `)

  const { richText } = nodes[0]
  const richText1 = renderRichText(richText, richTextOptions)

  return <WhatDoYouGain description={richText1} />
}

export default WhatDoYouGainOwner
