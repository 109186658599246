import React from 'react'
import Layout from '@/components/layout'
import { TestimonialSection } from '@/components/TestimonialSection'
import { PartnersSection } from '@/components/PartnersSection'
import { StopStartSection } from '@/components/StopStartSection'
import { Footer } from '@/components/Footer'
import WhatDoYouGainOwner from '@/components/SolutionPages/SmbOwner/WhatDoYouGainOwner'
import Benefits from '@/components/SolutionPages/Benefits'
import SolutionItOwnerHeader from '@/components/SolutionPages/SmbOwner/SolutionItOwnerHeader'
import ItOwnerProblemSolution from '@/components/SolutionPages/SmbOwner/ItOwnerProblemSolution'
import { graphql, useStaticQuery } from 'gatsby'

const SmbOwner: React.FC = () => {
  const {
    allContentfulSubPagesTitles: { nodes },
  } = useStaticQuery(graphql`
    {
      allContentfulSubPagesTitles {
        nodes {
          smbOwnerPage
        }
      }
    }
  `)

  const { smbOwnerPage } = nodes[0]
  return (
    <Layout title={smbOwnerPage}>
      <SolutionItOwnerHeader />
      <ItOwnerProblemSolution />
      {/*<TestimonialSection />*/}
      <PartnersSection />
      <WhatDoYouGainOwner />
      <Benefits />
      <StopStartSection />
      <Footer />
    </Layout>
  )
}

export default SmbOwner
